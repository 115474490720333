<template>
  <div class="inside-page-content">
    <div class="paper all-center">
      <div class="top-row">
        <img :src="detailData.pictureUrl" class="top-img" />
        <div class="top-info">
          <div class="top-name">
            <label class="type">{{detailData.patentName}}</label>
          </div>
          <div class="cost">
            <label>投入预算:</label>
            <label class="cost-num">{{detailData.inputBudget}}</label>
          </div>
          <div class="no">
            <label>专利号：{{detailData.patentNo}}</label>
            <label class="pl20">专利类型：{{patentTypeDic[detailData.patentType]}}</label>
            <label class="pl20">领域类别：{{filedCategoryDic[detailData.filedCategory]}}</label>
            <label class="pl20">合作方式：{{cooperationTypeDic[detailData.cooperationType]}}</label>
          </div>
          <button class="use" @click="showInfo">建立合作</button>
        </div>
      </div>
      <!--分割线-->
      <div class="divider"></div>

      <div class="detail-content">
        <div class="content-item">
          <div class="content-title">技术领域</div>
          <div class="content-text">{{filedCategoryDic[detailData.filedCategory]}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">用途</div>
          <div class="content-text">{{detailData.purpose}}</div>
        </div>
        <div class="content-item">
          <div class="content-title">待解决的技术问题</div>
          <div class="content-text">{{detailData.unsolveProblem}}</div>
        </div>
      </div>
    </div>
    <el-dialog
      title="联系方式"
      :visible.sync="open"
      width="800px"
      append-to-body
      :close-on-click-modal="false"
    >
      <div style="width: 100%;display: flex;flex-direction: row;justify-content: space-around;">
        <div>企业名称：{{detailData.companyName}}</div>
        <div>地址：{{detailData.address}}</div>
        <div>联系方式：{{detailData.phone}}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
import { mapState } from 'vuex'
export default {
  name: 'patentDetail',
  components: {
    TopFilter,
  },
  data() {
    return {
      open: false,
      patentTypeDic: { 1: "发明专利", 2: "实用新型" },
      filedCategoryDic: {
        1: "农林/牧渔/食品", 2: "交通/运输/包装", 3: "化工/冶金/生物", 4: "纺织/造纸", 5: "建筑/采矿/照明", 6: "机械/加工/设备", 7: "服饰/日用/家电", 8: '通信/电子/数码',
        9: '医疗/保健/美容',
        10: '能源/动力/电力',
        11: '法律领域',
      },
      cooperationTypeDic: { 1: "作价投资", 2: "合作", 3: "开发", 4: "许可", 5: "转让" },
      id: null,
      detailData: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user_info,
    }),
  },
  created(options) {
    this.id = this.$route.query?.id
    this.getDetail()
  },
  mounted() {

  },
  methods: {
    getDetail() {
      this.$api.intellectualProperty.patentDetail(this.id).then((res) => {
        this.detailData = res.data.data
      })
    },
    showInfo() {
      if (!this.user?.username) {
        this.$router.push("/logon");
      } else {
        this.open = true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-row {
  height: 130px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  .top-img {
    height: 67px;
    width: 117px;
  }
  .top-info {
    height: 100%;
    flex: 1;
    margin-left: 40px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-around;
    .top-name {
      display: flex;
      align-items: center;
      flex-direction: row;
      .type {
        font-weight: 700;
      }
    }
    .cost {
      font-size: 12px;
      .cost-num {
        font-size: 14px;
        color: red;
        padding-left: 10px;
        font-weight: 500;
      }
    }
    .no {
      font-size: 14px;
      font-weight: 500;
      .pl20 {
        padding-left: 20px;
      }
    }
    .use {
      font-size: 14px;
      background-color: blue;
      color: white;
    }
  }
}
.desc {
  display: flex;
  flex-direction: row;
  padding: 20px 80px;
  justify-content: space-around;
  .desc-item {
    display: flex;
    flex-direction: column;
    width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .info-text {
      padding-top: 10px;
      font-size: 14px;
      font-weight: 200;
    }
  }
}
.divider {
  width: 95%;
  margin: 20px auto;
  border-bottom: 1px dashed grey;
}
.detail-content {
  margin: 20px 80px;
  .content-item {
    margin: 40px 0;
    .content-title {
      border-left: 5px solid blue;
      padding-left: 10px;
      font-weight: 700;
    }
    .content-text {
      margin-top: 10px;
      font-size: 14px;
    }
  }
}
</style>
